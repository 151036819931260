import { useEffect } from 'react'
import { json, LoaderFunctionArgs } from '@remix-run/node'
import { useLoaderData, Outlet } from '@remix-run/react'
import type { MetaFunction } from '@remix-run/node'
import { getToast } from 'remix-toast'
import toast from 'react-hot-toast'
import { authenticator } from '~/domain/auth/services/authService'
import { Header } from '~/application/components/Header/Header'
import { Footer } from '~/application/components/Footer/Footer'
import { GetLoginUserByIdUseCase } from '~/domain/user/usecases/GetLoginUserByIdUseCase'
import { getTitle } from '~/application/services/metaService'
import { LoginUserDTO } from '~/domain/user/dto/LoginUserDTO'

export const meta: MetaFunction = () => {
  return [{ title: getTitle('') }]
}

export interface FrontContext {
  loginUser: LoginUserDTO
}

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await authenticator.isAuthenticated(request)
  const getLoginUserByIdUseCase = GetLoginUserByIdUseCase.create()
  const result = await getLoginUserByIdUseCase.execute(userId ?? 0)
  const { toast, headers } = await getToast(request)

  if (result.isErr()) {
    return json({
      loginUser: null,
      toast,
    })
  }

  return json(
    {
      loginUser: result.value,
      toast,
    },
    { headers },
  )
}

export default function Index() {
  const data = useLoaderData<typeof loader>()

  useEffect(() => {
    if (data.toast && data.toast.type === 'success') {
      toast.success(data.toast.message)
    }

    if (data.toast && data.toast.type === 'error') {
      toast.error(data.toast.message)
    }
  }, [data.toast])

  return (
    <>
      <Header loginUser={data.loginUser} />
      <Outlet
        context={{
          loginUser: data.loginUser,
        }}
      />
      <Footer />
    </>
  )
}
